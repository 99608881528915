/**
 * src/css/partials/logo.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */
.brand {
	width: 100%;
	flex: 0 0 7rem;
	transform: translateY(-1px); 	// Align to bottom border/line

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		fill: $primary-color;
	}

	#word path {
		fill: #fff;
	}

	[id^=wave] {
		// intentionally left blank
		position: static;
	}
}


.brand #waves {
	visibility: hidden;
}


@include for-size(tablet-landscape-up) {
	.brand {
		flex: 1 0 auto;

		svg {
			// transform: translateY(-20%);
		}
	}

	.brand #waves,
	.brand #illustration {
		visibility: visible;
	}
	
	#waves path {
		fill: none;
		stroke-width: 0.75px;
		stroke: $primary-color;
	}

}

