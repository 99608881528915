/**
 * src/css/components/image-and-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */

.quote-and-image {
	 // nothing to see here ;-)


	 &--image-right {
		 .quote-and-image__quote {
			 order: 2;
		 }
		 .quote-and-image__image {
			 order: 1;
		 }
	 }
}

@include for-size(tablet-landscape-up) {

	.quote-and-image__quote {
		grid-column: 2 / span 1;
		grid-row: 1;
		padding-right: $gutter;
		align-self: flex-end;
	}

	.quote-and-image__image {
		grid-column: 4 / span 3;
		grid-row: 1;
	}

	.quote-and-image--image-right {
		.quote-and-image__quote {
			grid-column: 6 / span 1;
		}
		.quote-and-image__image {
			grid-column: 2 / span 3;
		}
	}
}

