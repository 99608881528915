.dropdown {
	max-height: 0;
	transition: max-height 100ms ease-in-out;
	overflow: hidden;
	
	&:not([data-size]) {
		visibility: hidden;
	}
	
	&__button {
		@include for-size(tablet-landscape-up) {
			white-space: nowrap;
		}
		
		.icon svg {
			height: 0.75em;
			transform: translateY(-2%);
		}
		
		&[aria-expanded="true"] {
			
			.icon {
				transform: rotate(180deg);
			}
		}
	}
	
	&--open {
		transition: max-height 500ms ease-in-out;
		max-height: 1000rem !important;
		padding-bottom: 0.25rem; // fixes decender on last line
		
		&:not([data-size]) {
			visibility: visible;
		}
	}
}
