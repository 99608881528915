/**
 * src/css/components/partners.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-29
 */

.partners-list {
	@include grid;
	@include visible-gridlines;
	gap: $vertical-unit * 3 0;
}

.partners-list__item {
	grid-column: 3 / span 2;
}

.partner {
	display: flex;
	flex-wrap: wrap;
	flex-flow: row wrap;
}


.partner__body {
	padding-top: $vertical-unit;
	// max-width: 50%;
}

.partner__image {
	max-width: 100%; //calc(100% - #{$gutter * 2});
	flex: 1 0 100%;
	// max-width: 50%;

	img {
		aspect-ratio: 4/3;
		object-fit: cover;
	}
}


.partner__name {
	font-weight: bold;
	color: $accent-color;
	text-transform: uppercase;
	@include ms(4, 1.25);
	margin-bottom: 0.5 * $vertical-unit;
	word-break: break-word;
}

.partner__action-area {
	margin-top: $vertical-unit;
}


@include for-size(tablet-landscape-up) {

	.partner__image,
	.partner__body {
		flex: 1 1 100%;
	}

	.partners-list__item:nth-child(2n + 1) {
		grid-column: 2 / span 2;
	}

	.partners-list__item:nth-child(2n + 2) {
		grid-column: 5 / span 2;
	}


}


@include for-size(desktop-up) {

	.partners-list__item:nth-child(2n + 1) {
		grid-column: 3 / span 2;
	}

	.partner__image, .partner__body {
		flex: 0 0 50%;
	}

	.partner__body {
		padding-top: 0;
		padding-left: 2 * $gutter;
		> * {
			max-width: 14rem;
		}
	}
}


