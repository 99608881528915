/**
 * src/css/components/map.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */

.map__image {
	position: relative;
}

.map__caption {
	--pointer-height: 60px;
	--padding: 1.5%;
	position: absolute;

	width: 26%;
	width: max-content;
	max-width: 22rem;
	// height: 15%;
	// height: auto;

	right: 22%;
	bottom: 49%;

	z-index: 1;
	padding: var(--padding);
	padding-bottom: calc(var(--pointer-height) + var(--padding));

	background: $primary-color;
	color: #fff;
	@include ms(1);
	line-height: 1.1;

	clip-path: polygon(0 0, 0 calc(100% - var(--pointer-height)), 92% calc(100% - var(--pointer-height)), 100% 100%, 100% 0);

	// a {
	// 	color: currentColor;
	// 	&:hover {
	// 		text-decoration: none !important;
	// 	}
	// }
}


// @if $version == original {
// 	.map__text {
// 		margin-top: $vertical-unit;
// 		margin-bottom: $vertical-unit;
// 	}
//
// 	.map__caption {
// 		margin-bottom: $vertical-unit;
// 		background-color: yellow;
// 		margin-top: $vertical-unit;
// 	}
// }

.map__text {
	margin-top: $vertical-spacing-small;
	margin-bottom: $vertical-unit;
	grid-column: 2 / span 2;
}

@include for-size(tablet-portrait-up) {
	.map__image {
		grid-column: 2 / span 2;
	}
}

@include for-size(tablet-landscape-up) {
	.map__image {
		grid-column: 2 / span 2;
	}

	.map__text {
		grid-column: 5 / span 2;
		grid-row: 1;
	}
}
