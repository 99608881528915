/**
 * src/css/partials/grid.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */

/**
 * Every layout object should include this grid
 * by adding a class="grid"
 */
@mixin grid {

	display: grid;
	grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
	@include for-size(#{$grid-breakpoint}) {
		grid-template-columns: repeat(6, 2fr) #{$narrow-column-width};
	}

	> * {
		// Make grid columns fix width
		// @see: https://css-tricks.com/preventing-a-grid-blowout/
		// The content can still "overflow" the grid but the tracks themselves
		// stay fix.
		min-width: 0;
	}

	img {
		// outline: 1px solid #000; // only for development, remove later!
		width: 100%;
		max-width: 100%;
		display: block;

		// Make sure the grid lines are below all images (s.above)
		z-index: 1;
		position: relative;
	}

	// Mobile default behavior: Stack everything and stretch from second to
	// second-last column
	// Override in media queries
	> * {
		grid-column: 2 / -2;
	}
}

@mixin visible-gridlines {
	background-color: transparent;
	// Make sure the grid lines are below all images (s.above)
	position: relative;
	z-index: 0;

	background-repeat: repeat-x;
	background-image: linear-gradient(to right, $grid-line-color 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
	background-size: calc((100% - 2 * var(--margin)) / 3) 100%;
	background-position: var(--margin);

	@include for-size(#{$grid-breakpoint}) {
		background-repeat: repeat-x;
		background-image: linear-gradient(to right, $grid-line-color 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
		background-size: calc(100% / 13 * 2) 100%;
		background-size: calc((100% - #{$narrow-column-width}) / 6) 100%;
		background-position: 0 0;
	}
}

.grid {
	@include grid;
}

.grid.grid--visible-gridlines {
	@include visible-gridlines;
}


// Breaking the grid by having a gridless container as background with top and
// bottom borders
.grid-breakout-box {
	padding: 2 * $vertical-unit;
	background-color: $background-color;
	border: 1px solid $grid-line-color;
	border-right: 0;
}
