/**
 * src/css/partials/layout-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-24
 */
.layout-object {
	// @include visible-gridlines;

	&--padding-top-none {
		padding-top: 0;
	}

	&--padding-top-small {
		padding-top: $vertical-spacing-small;
	}

	&--padding-top-medium {
		padding-top: $vertical-spacing-medium;
	}

	&--padding-top-large {
		padding-top: $vertical-spacing-large;
	}

	&--padding-bottom-none {
		padding-bottom: 0;
	}

	&--padding-bottom-small {
		padding-bottom: $vertical-spacing-small;
	}

	&--padding-bottom-medium {
		padding-bottom: $vertical-spacing-medium;
	}

	&--padding-bottom-large {
		padding-bottom: $vertical-spacing-large;
	}

}
