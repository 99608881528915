/**
 * The Stack
 *
 * Spacing for vertically stacked blocks
 */
@mixin stack($spacing: $gutter) {
	> * + * {
		margin-top: $spacing;
	}
}


