/**
 * src/css/components/icon.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
.icon {
	@include icon;
}

.with-icon {
	/* ↓ Set the `inline-flex` context,  which eliminates the word space */  
	display: inline-flex;
	align-items: baseline;
}

.with-icon .icon {  
	/* ↓ Use the logical margin property  and a --space variable with a fallback */
	margin-inline-end: var(--space, 0.5em);
}



