/**
 * _settings.scss
 *
 * Use Sass variables and custom properties as appropriate, both are possible
 */


/**********
 * Colors *
 **********/
$background-color: rgb(242, 240, 241);
$primary-color: rgb(38, 46, 70);
$accent-color: rgb(205, 89, 88); //#eb5957;
$text-color: $primary-color;
 // "Text-on-dark", invert from text color
$contrast-color: color-for-bg($primary-color, $background-color, $text-color);
$grid-line-color: rgba(0, 0, 0, 0.1);


/************
 * Measures *
 ************/
$gutter: 1rem;
$header-height: 100px;
$divider-height: 152px;
// $inner-bound-width: 42rem;
// $outer-bound-width: 72rem;
$border-radius: 0px !default;
$border-width: 1px !default;

// Vertical unit on typographic scale,
// also base unit for layout spacing
$vertical-unit: 1.35rem;

// For layout spacing: Each one is double the previous
$vertical-spacing-small: 3 * $vertical-unit;
$vertical-spacing-medium: 6 * $vertical-unit;
$vertical-spacing-large: 12 * $vertical-unit;



/********************
 * Options/Switches *
 ********************/
$debug-base-line-grid: false;
$minimum-supported-screen-width: 320px;
$font-path: '/dist/fonts/';


///////////////////
//  Breakpoints  //
///////////////////
$tablet-portrait-breakpoint: 600px !default;
$tablet-landscape-breakpoint: 1000px !default;
$desktop-breakpoint: 1300px !default;
$big-desktop-breakpoint: 1800px !default;
$huge-desktop-breakpoint: 2400px !default;
// ... extend as needed

// The breakpoint where grid changes from 3 tpo 6 columns
$grid-breakpoint: tablet-landscape-up;

$narrow-column-width: 11rem;


/////////////////////////
//  Custom properties  //
/////////////////////////
:root {
	--gutter: 1rem;
	--vertical-unit: 1.5rem;
	--outer-bound: 2 * #{$gutter};
	--background-color: #{$background-color};
	--primary-color: #{$primary-color};
	--accent-color: #{$accent-color};
	--contrast-color: #{$contrast-color};
	--inner-bound-width: 42rem;
	--outer-bound-width: 72rem;
	--border-radius: 0px;
	--stack-spacing: #{$vertical-unit};
	--text-color: #{$text-color};
	--divider-height: #{$divider-height};
	//

	// Left/Right margin for the grid
	--margin: 25px; 

	@media screen and (min-width: #{$tablet-portrait-breakpoint}) {
		--margin: 40px;
	}
}
