/**
 * src/css/components/news.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-19
 */
.news {
	margin-top: 0;
	padding-top: 3 * $vertical-unit;
	padding-bottom: 3 * $vertical-unit;
	@include grid;
	@include visible-gridlines;
	.news-wrapper {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 2rem;
		grid-auto-flow: row;

	}
}

.news-teaser {
	background-color: $background-color;
	color: $text-color;
	// overflow: hidden;
	padding: 2rem 2rem 2rem 2rem;

	outline-width: 0px;
	outline-style: solid;
	outline-color: currentColor;
	outline-offset: -1rem;

	outline: 1px solid currentColor;
	outline-offset: 0;

	display: flex;
	flex-direction: column;
	// aspect-ratio: 1 / 1.01;
	overflow: hidden;

	// min-aspect-ratio
	// height: auto;
	// overflow: auto;
	// position: relative;
	// &::before {
	// 	content: '';
	// 	padding-bottom: 100%;
	// 	float: left;
	// }
    //
	// &::after {
	// 	content: '';
	// 	display: table;
	// 	clear: both;
	// }



	&--bg-primary { background-color: $primary-color; outline: none;}
	&--bg-accent { background-color: $accent-color; outline: none;}
	&--bg-background { background-color: $background-color; }
	&--bg-white { background-color: #fff; }
	&--fg-primary { color: $primary-color; }
	&--fg-accent { color: $accent-color; }
	&--fg-background { color: $background-color; }
	&--fg-white { color: #fff; }
	$ms: 3;

	.headline {
		color: currentColor;
		@include ms($ms, 1.65);
		margin-bottom: 0.25rem;
	}

	.headline {
		@include ms($ms * 2, 1.5);
	}

	.body-text {
		color: currentColor;
		@include ms($ms);
		line-height: 1.35em;
	}

	&__title .headline {
		font-weight: bold;
	}

	&__subtitle .headline {
		font-weight: 100 !important;
	}



	.illu {
		display: none;
		width: 100%;
		// border-bottom: 1px solid currentColor;
		margin-top: auto;
		fill: currentColor;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&__subtitle {
		.headline {
			font-weight: 400;
		}
	}

	&__text {
		margin-top: $vertical-unit;
	}
}

@media screen and (max-width: #{$tablet-portrait-breakpoint + 1}) {
	.news-teaser {
		grid-column: auto / span 6;
	}
}

@media screen and (min-width: #{$tablet-portrait-breakpoint + 1}) and (max-width: #{$desktop-breakpoint - 1}) {
	.news-teaser {
		grid-column: auto / span 3;
		&:nth-child(2n + 1) {
			// grid-column-start: 2;
		}
	}
}

@media screen and (min-width: #{$desktop-breakpoint + 1}) {
	.news-teaser {
		grid-column: auto / span 2;
		&:nth-child(3n + 1) {
			// grid-column-start: 2;
		}
	}
}


a.news-teaser {
	align-items: center;
	justify-content: center;
	min-height: 12 * $vertical-unit;
}

a.news-teaser.news-load-next-btn {
	border: none;
	text-decoration: none;
	transition: 150ms ease-out;

	&:hover,
	&:focus {
		background-color: $accent-color;
		color: #fff;
	}
}
