/**
 * libhalma/_rwd.scss
 *
 * Breakpoints, media queries, responsive webdesign
 * See: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */

@mixin for-size($size) {
	@if $size == phone {
		@media (max-width: #{$tablet-portrait-breakpoint - 1}) {
			@content;
		}
	}
	@else if $size == tablet-portrait-up {
		@media (min-width: #{$tablet-portrait-breakpoint}) {
			@content;
		}
	}
	@else if $size == tablet-landscape-down {
		@media (max-width: #{$tablet-landscape-breakpoint}) {
			@content;
		}
	}
	@else if $size == tablet-landscape-up {
		@media (min-width: #{$tablet-landscape-breakpoint}) {
			@content;
		}
	}
	@else if $size == desktop-up {
		@media (min-width: #{$desktop-breakpoint}) {
			@content;
		}
	}
	@else if $size == big-desktop-up {
		@media (min-width: #{$big-desktop-breakpoint}) {
			@content;
		}
	}
	@else if $size == huge-desktop-up {
		@media (min-width: #{$huge-desktop-breakpoint}) {
			@content;
		}
	}
	// ... extend as needed
}
