/**
 * src/css/partials/_typography.scss
 *
 * @author Johannes Braun </j.braun@agentur-halm: a.de>
 * @package bus
 * @version 2021-06-30
 *
 * Typography related styles
 */

$font-sizes: [
	1rem, 			
	1.15384rem, 		// 15px / 13px
	1.61538rem, 		// 21px / 13px
	1.91666rem, 		// 
	4.07692rem,
	6.15384rem,
	9.53846rem
];

$line-heights: [];

@function major-second($n) {
	@return pow(1.125, $n - 1);
}

@mixin ms($level, $line-height-factor: 1) {
	// font-size: nth($font-sizes, $level);
	$font-size: major-second($level);
	$line-height: strip-unit($vertical-unit) / $font-size * $line-height-factor;
	font-size: $font-size + rem;
	line-height: $line-height + em;
}
	


html {
	// @include fluid-type(font-size, $tablet-portrait-breakpoint, $desktop-breakpoint, 11px, 22px);
	font-size: 10px;
	@include for-size(tablet-landscape-up) {
		font-size: 13px;
	}
}

body {
	// Sets up font-size and line-height
	// according to vertical rhythm.
	// @see mixins in mixins/_vertical_rhythm.scss
	font-family: Nexa, sans-serif;
	@include ms(2);
	color: var(--text-color);
}

blockquote {
	@include ms(4);
}

.headline {

	font-weight: bold;
	line-height: 1em;

	&--small {
		@include ms(8, 2);
		margin-bottom: 1 *$vertical-unit;
	}

	&--default {
		@include ms(10, 2);
		margin-bottom: 1 *$vertical-unit;
		&--large {
	}

		@include ms(12, 3);
		margin-bottom: 1 *$vertical-unit;
	}


	@include for-size(tablet-landscape-up) {

		&--small {
			@include ms(10, 2);
		}

		&--default {
			@include ms(14, 3);
		}

		&--large {
			@include ms(16, 4);
		}
	}

	@include for-size(desktop-up) {

		&--small {
			@include ms(12, 3);
		}

		&--default {
			@include ms(16, 4);
		}

		&--large {
			@include ms(18, 5);
		}

	}
}

.layout-object > .headline {
	margin-top: 3 * $vertical-unit;
	margin-bottom: 1 * $vertical-unit;
}

.grid > .headline {
	@include for-size(tablet-landscape-up) {
		grid-column: 2 / span 4;
	}

	@include for-size(desktop-up) {
		grid-column: 2 / span 3;
	}
}

// Handle word-wrap / hyphenation
// We have a very heavy headings font-size, 
// so breaking will(!) be a concern!!
.layout-object--word-wrap-enabled {
	.headline,
	.quote {
		hyphens: auto;
		// overflow-wrap: anywhere;
	}
}

.layout-object--word-wrap-disabled {
	overflow-x: hidden;
	.headline,
	.quote {
		hyphens: none;
		overflow-wrap: normal;
		// Overflow can cause horizontal scrollbars
		// overflow: hidden;
	}
}


.body-text,
.mce-content-body{
	font-weight: 100;
	line-height: 1.65em;
	color: var(--text-color);

	p {
		margin-bottom: $vertical-unit;
	}
}

.body-text + .body-text {
	margin-top: $vertical-unit;
}

.grid > .body-text {
	grid-column: 2 / span 3;
	@include for-size(tablet-landscape-up) {
		grid-column: 2 / span 4;
	}
	@include for-size(desktop-up) {
		grid-column: 2 / span 3;
	}
	@include for-size(big-desktop-up) {
		grid-column: 2 / span 2;
	}
}


.quote {
	@include ms(9, 2);
	font-weight: 100;

	// &--small {
	// 	@include ms(7, 2);
	// }
    //
	// &--large {
	// 	@include ms(11, 3);
	// }

	&::before {
		content: '„';
	}

	&::after {
		content: '“';
	}

	@include ms(11, 3);
	margin-bottom: $vertical-unit;

	@include for-size(tablet-landscape-up) {
		@include ms(11, 3);
		&--small {
			@include ms(10, 3);
		}

		&--large {
			@include ms(14, 5);
		}
	}

	@include for-size(big-desktop-up) {
		@include ms(14, 4);

	}

}

:root {
	--hover-effect-width: 1px;
}

a:focus {
	outline: 1px dotted #{$accent-color};
}

main {
	--hover-effect-color1: #{$accent-color};
	--hover-effect-color2: #{$primary-color};
	--hover-effect-bgcol: #{$background-color};
}

.map__caption,
.main-footer,
.m-modal {
	--hover-effect-width: 1px;
	--hover-effect-color1: #{$accent-color};
	--hover-effect-color2: #fff;
	--hover-effect-bgcol: #{$primary-color};
}

/**
 * Link hover erffect
 */


.body-text,
.map__caption,
.main-footer,
.partner,
.m-modal {
	a:not(.button) {
		// color: $text-color;
		color: currentColor;

		text-decoration: none;
		box-decoration-break: slice;
		background-image: 
			linear-gradient(var(--hover-effect-bgcol), var(--hover-effect-bgcol)), 
			linear-gradient(var(--hover-effect-color1), var(--hover-effect-color1)),
			linear-gradient(var(--hover-effect-color2), var(--hover-effect-color2))
		;
		background-size: 20px var(--hover-effect-width), 100% var(--hover-effect-width), 0 var(--hover-effect-width);
		background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;
		background-repeat: no-repeat;
		transition: 300ms ease-out;

		&:hover,
		&:focus {
			text-decoration: none;
			background-size: 20px var(--hover-effect-width), 0 var(--hover-effect-width), 100% var(--hover-effect-width);
			background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%;
		}
	}
}


.body-text,
.mce-content-body {

	ul {
		--marker-width: 1.5rem;
		margin-bottom: $vertical-unit;
		list-style: none;

		> li + li {
			margin-top: 0.5 * $vertical-unit;
		}

	}

	li {
		position: relative;
		padding-left: calc(0.75em);
		&::before {
			content: '•';
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

strong, b {
	font-weight: bold;
}
