/**
 * src/css/partials/_webfonts.scss
 *
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */
@font-face {
	font-family: 'Nexa';
	src: url("../fonts/NexaLight/font.woff2") format("woff2"), url("../fonts/NexaLight/font.woff") format("woff");
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: 'Nexa';
	src: url("../fonts/NexaBook/font.woff2") format("woff2"), url("../fonts/NexaBook/font.woff") format("woff");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Nexa';
	src: url("../fonts/NexaHeavy/font.woff2") format("woff2"), url("../fonts/NexaHeavy/font.woff") format("woff");
	font-style: normal;
	font-weight: bold;
}
