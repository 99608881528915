/**
 * src/css/components/introduction-hero.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */
.introduction-hero {

	.introduction-hero__text > * + * {
		margin-top: $vertical-unit;
	}

	.introduction-hero__quote {
		grid-column: 2 / span 3;
		grid-row: 5;
		@include for-size(tablet-portrait-up) {
			grid-column: 2 / span 1;
			grid-row: auto;
			padding-right: $gutter;
		}
		font-weight: 100;
		margin-top: 1 * $vertical-unit;
	}

	.introduction-hero__main-image {
		z-index: -1;
	}

	.introduction-hero__secondary-image {
		grid-column: 3 / -1;
		transform: translateY(-3rem);
		margin-bottom: -3rem;
		z-index: -1;
	}

	@include for-size(tablet-portrait-up) {
		.introduction-hero__headline {
			grid-column: 2 / span 2;
		}

		.introduction-hero__text {
			margin-bottom: 3 * $vertical-unit;
			grid-column: 2 / span 2;
		}
	}

	@include for-size(tablet-landscape-up) {

		.introduction-hero__headline {
			grid-column: 2 / span 2;
			grid-row: 1 / 2;
			position: relative;
			max-height: 12rem; // This will make the title "overlay" the image
			z-index: 1;
		}
		
		.introduction-hero__text {
			grid-column: 5 / span 2;
			grid-row: 1 / 2;
		}

		.introduction-hero__main-image {
			grid-column: 2 / span 6;
			grid-row: 2 / 3;
		}

		.introduction-hero__quote {
			grid-column: 2 / span 2;
			grid-row: 3 / 4;
		}

		.introduction-hero__secondary-image {
			grid-column: 4 / span 3;
			grid-row: 3 / 4;
			transform: translateY(-6rem);
			margin-bottom: -6rem;
		}
	}
}
