@charset "UTF-8";
/**
 * main.scss
 */
/**
 * _settings.scss
 *
 * Use Sass variables and custom properties as appropriate, both are possible
 */
/**********
 * Colors *
 **********/
/************
 * Measures *
 ************/
/********************
 * Options/Switches *
 ********************/
:root {
  --gutter: 1rem;
  --vertical-unit: 1.5rem;
  --outer-bound: 2 * 1rem;
  --background-color: #f2f0f1;
  --primary-color: #262e46;
  --accent-color: #cd5958;
  --contrast-color: color-for-bg(#262e46, #f2f0f1, #262e46);
  --inner-bound-width: 42rem;
  --outer-bound-width: 72rem;
  --border-radius: 0px;
  --stack-spacing: 1.35rem;
  --text-color: #262e46;
  --divider-height: 152px;
  --margin: 25px;
}

@media screen and (min-width: 600px) {
  :root {
    --margin: 40px;
  }
}

/**
 * Libraries
 * Libraries do not produce any actual CSS code at all, they just provide
 * @mixins which can be used in components and partials
 */
/**
 * src/css/libhalma/_libhalma.scss
 *
 * Libhalma: A simple, solid and accessible basic library for css
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2020-11-09
 */
/**
 * src/css/libhalma/_classes.scss
 *
 * Predefined utility classes
 *
 * @author C.Coull <c.coull@agentur-halma.de>
 * @author J. Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-27
 */
/**
 * src/css/everylayout/icon.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
/**
 * src/css/libhalma/media-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
/**
 * libhalma/_rwd.scss
 *
 * Breakpoints, media queries, responsive webdesign
 * See: https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */
/**
 * _ui.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */
/**
 * Reset button
 * See: https://fvsch.com/code/styling-buttons/
 */
.button, .m-open, .m-copy, .menu-trigger {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

/**
 * src/css/mixins/_utils.scss
 *
 * Utility mixins and functions
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */
/**
 * strip-unit
 *
 * Returns the unitless number
 * From: https://css-tricks.com/snippets/sass/strip-unit-function/
 *
 * @param number
 * @return number
 */
/**
 * pow
 *
 * Return the number risen to the power of n
 * From: https://css-tricks.com/snippets/sass/power-function/
 *
 * @param number 	The operator
 * @param exp 		The exponent
 * @return number
 */
/**
 * Calculates the sRGB luma of a colour.
 *
 * From: https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 *
 * Math nicked from a great Thoughtbot article by Reda Lemeden:
 * http://robots.thoughtbot.com/closer-look-color-lightness
 */
/*
 * Picks a colour from two options based on which one is more visible
 * on the given background colour.
 *
 * From: https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 *
 * Usage: color-for-bg($bg-color, $color-1, $color-2)
 */
/*
 * @author Carsten Coull <c.coull@agentur-halma.de>
 *
 * Slope Utilits
 * sets a Graph to use for unit
 *
 * Needs a calc support
 */
/*
 * for fluid type to fit to screen;
 */
/*
 * Sets a fluid Unit between to Values
 *
 * $min-size: Minimum Value
 * $max-size: Maximum Value
 * $min-view: Size at which to use $min-size
 * $max-view: Size at which to use $max-size
 *
 * works best with px Unit
 */
/* Can be used with Custom Proporties */
/**
 * src/css/libhalma/_vertical_rhythm.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */
/**
 * The vertical unit
 * Give with rem as unit, e.g. 1.5rem
 */
/**
 * Sets font-size and line-height according to a given level, containing a
 * vertical rhythm based on the vertical unit (global var $vertical-unit)
 *
 * Here we use rem units only to refer to the html's font-size which has been set
 * by the fluid typography section above
 *
 * @param int $level 		 	The font size level to use
 * @param int $margin-bottom 	optional: Add bottom margin in n times the vertical unit
 */
/**
 * src/css/everylayout.scss
 *
 * Taken from EveryLayout
 */
/**
 * The Stack
 *
 * Spacing for vertically stacked blocks
 */
/**
 * From: EveryLayout
 */
/**
 * The Cluster
 *
 * https://every-layout.dev/demos/cluster-ctas/
 *
 * We can use `gap` now on flexbox items, so it gets really easy now :-)
 */
/**
 * Partials:
 * Partials are used for general purpose stylesnot regarding a specific
 * component but more general styles, like f.e. typography, common layout,
 * global styles, header, footer etc. ...
 */
/**
 * home/jbraun/Sites/halma-kickstart/src/css/libhalma/_reset.scss
 *
 * inspired by https://hankchizljaw.com/wrote/a-modern-css-reset/
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2020-11-09
 */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  line-height: 1.35;
  text-rendering: optimizeSpeed;
}

main {
  text-rendering: optimizeLegibility;
}

img, picture, video {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}

ol, ul {
  list-style: none;
  counter-reset: li;
}

ol > li::before {
  content: counter(li) ".";
  counter-increment: li;
}

input, button, textarea, select {
  font: inherit;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

@media (prefers-reduced-motion: reduce) {
  *, *::before, *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/**
 * partials/_common.scss
 *
 * Common styles, like e.g. resets, basic styles...
 */
body {
  background-color: var(--primary-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

body.mce-content-body {
  background-color: var(--background-color);
  padding: 1rem;
}

* {
  scroll-behavior: smooth;
}

.cmt-layout {
  background-color: var(--background-color) !important;
}

.main-content {
  background-color: var(--background-color);
  flex: 1;
}

.button, .m-open, .m-copy {
  text-align: center;
  text-decoration: none;
  margin: 2px 0;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0px;
  padding: 0.5em 1em;
  color: #f2f0f1;
  background-color: #262e46;
  outline: none;
  display: inline-flex;
  align-items: center;
}

.button::-moz-focus-inner, .m-open::-moz-focus-inner, .m-copy::-moz-focus-inner {
  border: none;
}

.button:active, .m-open:active, .m-copy:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.button:hover, .m-open:hover, .m-copy:hover, .button:focus, .m-open:focus, .m-copy:focus {
  background-color: #f2f0f1;
  color: #262e46;
  border-color: currentColor;
}

.button > span + span, .m-open > span + span, .m-copy > span + span {
  margin-left: 0.75ch;
}

.sr-only {
  display: inline-block;
  opacity: 0;
  background: none;
  position: absolute;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  width: 2px;
  height: 2px;
  margin: -1px;
}

.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.icon {
  height: 0.75em;
  /* ↓ Override the em value with `1cap`  where `cap` is supported */
  height: 1cap;
  width: 0.75em;
  width: 1cap;
}

.icon svg {
  fill: currentColor !important;
  stroke: none;
  display: block;
  width: 100%;
  height: 100%;
}

.cluster > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: calc(1rem / 2 * -1);
}

.cluster > * > * {
  margin: calc(1rem / 2);
}

.stack > * + * {
  margin-top: var(--stack-spacing);
}

/**
 * src/css/partials/_webfonts.scss
 *
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */
@font-face {
  font-family: 'Nexa';
  src: url("../fonts/NexaLight/font.woff2") format("woff2"), url("../fonts/NexaLight/font.woff") format("woff");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Nexa';
  src: url("../fonts/NexaBook/font.woff2") format("woff2"), url("../fonts/NexaBook/font.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Nexa';
  src: url("../fonts/NexaHeavy/font.woff2") format("woff2"), url("../fonts/NexaHeavy/font.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

/**
 * src/css/partials/_typography.scss
 *
 * @author Johannes Braun </j.braun@agentur-halm: a.de>
 * @package bus
 * @version 2021-06-30
 *
 * Typography related styles
 */
html {
  font-size: 10px;
}

@media (min-width: 1000px) {
  html {
    font-size: 13px;
  }
}

body {
  font-family: Nexa, sans-serif;
  font-size: 1.125rem;
  line-height: 1.2em;
  color: var(--text-color);
}

blockquote {
  font-size: 1.42383rem;
  line-height: 0.94815em;
}

.headline {
  font-weight: bold;
  line-height: 1em;
}

.headline--small {
  font-size: 2.2807rem;
  line-height: 1.18385em;
  margin-bottom: 1.35rem;
}

.headline--default {
  font-size: 2.88651rem;
  line-height: 0.93539em;
  margin-bottom: 1.35rem;
  font-size: 3.65324rem;
  line-height: 1.10861em;
  margin-bottom: 1.35rem;
}

@media (min-width: 1000px) {
  .headline--small {
    font-size: 2.88651rem;
    line-height: 0.93539em;
  }
  .headline--default {
    font-size: 4.62363rem;
    line-height: 0.87594em;
  }
  .headline--large {
    font-size: 5.85178rem;
    line-height: 0.9228em;
  }
}

@media (min-width: 1300px) {
  .headline--small {
    font-size: 3.65324rem;
    line-height: 1.10861em;
  }
  .headline--default {
    font-size: 5.85178rem;
    line-height: 0.9228em;
  }
  .headline--large {
    font-size: 7.40616rem;
    line-height: 0.9114em;
  }
}

.layout-object > .headline {
  margin-top: 4.05rem;
  margin-bottom: 1.35rem;
}

@media (min-width: 1000px) {
  .grid > .headline {
    grid-column: 2 / span 4;
  }
}

@media (min-width: 1300px) {
  .grid > .headline {
    grid-column: 2 / span 3;
  }
}

.layout-object--word-wrap-enabled .headline,
.layout-object--word-wrap-enabled .quote {
  hyphens: auto;
}

.layout-object--word-wrap-disabled {
  overflow-x: hidden;
}

.layout-object--word-wrap-disabled .headline,
.layout-object--word-wrap-disabled .quote {
  hyphens: none;
  overflow-wrap: normal;
}

.body-text,
.mce-content-body {
  font-weight: 100;
  line-height: 1.65em;
  color: var(--text-color);
}

.body-text p,
.mce-content-body p {
  margin-bottom: 1.35rem;
}

.body-text + .body-text {
  margin-top: 1.35rem;
}

.grid > .body-text {
  grid-column: 2 / span 3;
}

@media (min-width: 1000px) {
  .grid > .body-text {
    grid-column: 2 / span 4;
  }
}

@media (min-width: 1300px) {
  .grid > .body-text {
    grid-column: 2 / span 3;
  }
}

@media (min-width: 1800px) {
  .grid > .body-text {
    grid-column: 2 / span 2;
  }
}

.quote {
  font-size: 2.56578rem;
  line-height: 1.05231em;
  font-weight: 100;
  font-size: 3.24732rem;
  line-height: 1.24718em;
  margin-bottom: 1.35rem;
}

.quote::before {
  content: '„';
}

.quote::after {
  content: '“';
}

@media (min-width: 1000px) {
  .quote {
    font-size: 3.24732rem;
    line-height: 1.24718em;
  }
  .quote--small {
    font-size: 2.88651rem;
    line-height: 1.40308em;
  }
  .quote--large {
    font-size: 4.62363rem;
    line-height: 1.45989em;
  }
}

@media (min-width: 1800px) {
  .quote {
    font-size: 4.62363rem;
    line-height: 1.16791em;
  }
}

:root {
  --hover-effect-width: 1px;
}

a:focus {
  outline: 1px dotted #cd5958;
}

main {
  --hover-effect-color1: #cd5958;
  --hover-effect-color2: #262e46;
  --hover-effect-bgcol: #f2f0f1;
}

.map__caption,
.main-footer,
.m-modal {
  --hover-effect-width: 1px;
  --hover-effect-color1: #cd5958;
  --hover-effect-color2: #fff;
  --hover-effect-bgcol: #262e46;
}

/**
 * Link hover erffect
 */
.body-text a:not(.button):not(.m-open):not(.m-copy),
.map__caption a:not(.button):not(.m-open):not(.m-copy),
.main-footer a:not(.button):not(.m-open):not(.m-copy),
.partner a:not(.button):not(.m-open):not(.m-copy),
.m-modal a:not(.button):not(.m-open):not(.m-copy) {
  color: currentColor;
  text-decoration: none;
  box-decoration-break: slice;
  background-image: linear-gradient(var(--hover-effect-bgcol), var(--hover-effect-bgcol)), linear-gradient(var(--hover-effect-color1), var(--hover-effect-color1)), linear-gradient(var(--hover-effect-color2), var(--hover-effect-color2));
  background-size: 20px var(--hover-effect-width), 100% var(--hover-effect-width), 0 var(--hover-effect-width);
  background-position: calc(20px * -1) 100%, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: 300ms ease-out;
}

.body-text a:not(.button):not(.m-open):not(.m-copy):hover, .body-text a:not(.button):not(.m-open):not(.m-copy):focus,
.map__caption a:not(.button):not(.m-open):not(.m-copy):hover,
.map__caption a:not(.button):not(.m-open):not(.m-copy):focus,
.main-footer a:not(.button):not(.m-open):not(.m-copy):hover,
.main-footer a:not(.button):not(.m-open):not(.m-copy):focus,
.partner a:not(.button):not(.m-open):not(.m-copy):hover,
.partner a:not(.button):not(.m-open):not(.m-copy):focus,
.m-modal a:not(.button):not(.m-open):not(.m-copy):hover,
.m-modal a:not(.button):not(.m-open):not(.m-copy):focus {
  text-decoration: none;
  background-size: 20px var(--hover-effect-width), 0 var(--hover-effect-width), 100% var(--hover-effect-width);
  background-position: calc(100% + 20px) 100%, 100% 100%, 0 100%;
}

.body-text ul,
.mce-content-body ul {
  --marker-width: 1.5rem;
  margin-bottom: 1.35rem;
  list-style: none;
}

.body-text ul > li + li,
.mce-content-body ul > li + li {
  margin-top: 0.675rem;
}

.body-text li,
.mce-content-body li {
  position: relative;
  padding-left: calc(0.75em);
}

.body-text li::before,
.mce-content-body li::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}

strong, b {
  font-weight: bold;
}

/**
 * src/css/partials/grid.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
/**
 * Every layout object should include this grid
 * by adding a class="grid"
 */
.grid {
  display: grid;
  grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
}

@media (min-width: 1000px) {
  .grid {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

.grid > * {
  min-width: 0;
}

.grid img {
  width: 100%;
  max-width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}

.grid > * {
  grid-column: 2 / -2;
}

.grid.grid--visible-gridlines {
  background-color: transparent;
  position: relative;
  z-index: 0;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  background-size: calc((100% - 2 * var(--margin)) / 3) 100%;
  background-position: var(--margin);
}

@media (min-width: 1000px) {
  .grid.grid--visible-gridlines {
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    background-size: calc(100% / 13 * 2) 100%;
    background-size: calc((100% - 11rem) / 6) 100%;
    background-position: 0 0;
  }
}

.grid-breakout-box {
  padding: 2.7rem;
  background-color: #f2f0f1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 0;
}

/**
 * src/css/partials/main-header.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */
body {
  --header-size: 13rem;
}

@media (max-width: 1000px) {
  body.has-scrolled {
    --header-size: 5.9rem;
  }
}

@media (min-width: 1000px) {
  body {
    --header-size: 11rem;
  }
}

.main-header {
  flex: 0 0 var(--header-size);
  background-color: var(--accent-color);
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: var(--header-size);
  transition: 150ms ease-out;
  padding-left: var(--margin);
  padding-right: var(--margin);
  padding-top: 0;
  padding-bottom: 1rem;
  z-index: 3;
}

@media (min-width: 1000px) {
  .main-header {
    padding: 0 1rem 2rem 1rem;
    height: 100vh;
    max-width: var(--header-size);
  }
  .main-header > * {
    max-width: var(--header-size);
  }
}

.main-header__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.main-nav {
  margin: auto;
}

.opening-hours {
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 1rem;
  transition: opacity .1s ease-in;
}

.opening-hours__dropdown-trigger {
  display: flex;
  align-items: center;
  position: relative;
  background: var(--primary-color);
  color: #fff;
  padding: .5rem 1rem;
  border-radius: 100rem;
  appearance: none;
  border: none;
  cursor: pointer;
}

.opening-hours__dropdown-trigger:after {
  --size: .6em;
  content: "";
  display: inline-block;
  margin: calc(var(--size) * -.8) 0 0 0.5em;
  width: var(--size);
  height: var(--size);
  border: .2em solid;
  border-color: #fff #fff transparent transparent;
  transform: rotateZ(135deg);
}

.opening-hours__dropdown-trigger[aria-expanded="true"]:after {
  margin-top: calc(var(--size) * .5);
  transform: rotateZ(315deg);
}

.opening-hours__dropdown {
  position: absolute;
  padding: 1rem !important;
  width: 18rem;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary-color);
  color: #fff;
  border-radius: 1rem;
}

.opening-hours__dropdown th, .opening-hours__dropdown td {
  color: #fff;
  padding: .2rem 0;
}

.opening-hours__dropdown th {
  padding-right: 1em;
  text-align: left;
}

body.menu-is-open .opening-hours {
  opacity: 0;
}

@media (min-width: 1000px) {
  .main-header__top {
    --h: 236px;
    flex: 0 0 var(--h);
    min-height: var(--h);
    width: calc(11rem - 2rem);
    max-width: calc(11rem - 2rem);
    margin: 0 auto;
    transform: translateY(var(--yoffset));
  }
  .menu-trigger {
    display: none;
  }
  .main-menu {
    position: static !important;
    display: flex !important;
    height: auto !important;
    flex: 1 0 auto;
  }
  .opening-hours {
    margin-left: calc((100vw - 22rem) / 6 + 1rem);
  }
}

/**
 * src/css/partials/main-menu.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */
.main-menu a {
  color: var(--accent-color);
  text-decoration: none;
  display: inline-block;
}

.menu-trigger {
  flex: 0;
  color: #262e46;
  padding: 0;
  margin-top: 1rem;
}

.menu-trigger svg {
  display: block;
  width: 3rem;
  height: 100%;
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-width: 8px;
}

.menu-trigger .cross {
  visibility: hidden;
}

.menu-trigger .hamburger {
  visibility: visible;
}

.main-menu {
  position: absolute;
  top: var(--header-size);
  width: 100%;
  left: 0;
  height: calc(100vh - var(--header-size));
  background-color: var(--accent-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  transition: opacity 150ms ease-out;
  opacity: 1;
}

.menu-is-open .menu-trigger .cross {
  visibility: visible;
}

.menu-is-open .menu-trigger .hamburger {
  visibility: hidden;
}

@media (max-width: 999px) {
  body:not(.menu-is-open) .main-menu {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }
}

/**
 * src/css/partials/logo.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */
.brand {
  width: 100%;
  flex: 0 0 7rem;
  transform: translateY(-1px);
}

.brand svg {
  width: 100%;
  height: 100%;
}

.brand path {
  fill: #262e46;
}

.brand #word path {
  fill: #fff;
}

.brand [id^=wave] {
  position: static;
}

.brand #waves {
  visibility: hidden;
}

@media (min-width: 1000px) {
  .brand {
    flex: 1 0 auto;
  }
  .brand #waves,
  .brand #illustration {
    visibility: visible;
  }
  #waves path {
    fill: none;
    stroke-width: 0.75px;
    stroke: #262e46;
  }
}

/**
 * src/css/layout.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */
.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@media (min-width: 1000px) {
  .main-container {
    flex-direction: row;
  }
}

.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.spacer.grid {
  flex: 1 1 auto;
}

/**
 * src/css/partials/layout-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-24
 */
.layout-object--padding-top-none {
  padding-top: 0;
}

.layout-object--padding-top-small {
  padding-top: 4.05rem;
}

.layout-object--padding-top-medium {
  padding-top: 8.1rem;
}

.layout-object--padding-top-large {
  padding-top: 16.2rem;
}

.layout-object--padding-bottom-none {
  padding-bottom: 0;
}

.layout-object--padding-bottom-small {
  padding-bottom: 4.05rem;
}

.layout-object--padding-bottom-medium {
  padding-bottom: 8.1rem;
}

.layout-object--padding-bottom-large {
  padding-bottom: 16.2rem;
}

/**
 * src/css/partials/menu.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */
.menu a {
  display: inline-block;
  text-decoration: none;
  color: #262e46;
  font-size: 2.2807rem;
  line-height: 1.18385em;
}

.main-menu {
  padding-bottom: 1.35rem;
}

@media (min-width: 600px) {
  .main-menu {
    padding-bottom: 2.7rem;
  }
}

@media (min-width: 1000px) {
  .main-menu {
    padding-bottom: 0;
  }
}

.main-nav {
  --main-menu-vertical-spacing: calc(1 * 1.35rem);
}

.main-nav .menu {
  text-align: center;
}

.main-nav .menu li + li {
  margin-top: var(--main-menu-vertical-spacing);
}

.main-nav .menu a {
  font-weight: bold;
  max-width: 70vw;
}

@media (min-width: 600px) {
  .main-nav {
    --main-menu-vertical-spacing: calc(2 * 1.35rem);
  }
}

@media (min-width: 1000px) {
  .main-nav {
    --main-menu-vertical-spacing: calc(1 * 1.35rem);
  }
  .main-nav a {
    font-size: 1rem;
    line-height: 1.35em;
  }
}

.sec-nav {
  --social-media-size: 2.5rem;
}

.sec-nav .menu {
  text-align: center;
}

.sec-nav .menu li:first-child {
  padding-top: 6ch;
}

.sec-nav .menu li:first-child a {
  transform-origin: center center;
  text-transform: uppercase;
}

@media screen and (min-height: 700px) {
  .sec-nav .menu li:first-child a {
    transform: rotate(-90deg) translateX(50%);
  }
}

.sec-nav .menu li + li {
  margin-top: 0.675rem;
}

.sec-nav .menu a {
  font-size: 1.80203rem;
  line-height: 0.74915em;
}

@media (min-width: 1000px) {
  .sec-nav .menu a {
    font-size: 1.125rem;
    line-height: 1.2em;
  }
}

.sec-nav .menu svg {
  width: var(--social-media-size);
  height: var(--social-media-size);
  fill: #262e46;
}

@media (min-width: 1000px) {
  .sec-nav {
    --social-media-size: 1.5rem;
  }
  .sec-nav .menu a {
    font-size: 1rem;
    line-height: 1.35em;
  }
}

@media (min-width: 1300px) {
  .sec-nav {
    --social-media-size: 1.5rem;
  }
  .sec-nav .menu a {
    font-size: 1rem;
    line-height: 1.35em;
  }
}

/**
 * src/css/partials/footer.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.main-footer {
  margin-top: auto;
  background: var(--primary-color);
  padding-top: 9.1125rem;
  padding-bottom: 4.05rem;
  min-height: 100px;
  --text-color: #fff;
}

.main-footer a {
  color: var(--text-color);
  display: inline-block;
}

@media (min-width: 1000px) {
  .footer__logos {
    grid-column: 6 / span 1;
    grid-row: 1;
  }
  .footer__address {
    grid-column: 5 / span 1;
    grid-row: 1;
  }
  .footer__nav {
    grid-column: 2 / span 1;
    grid-row: 1;
    align-self: flex-start;
  }
}

.footer__nav {
  color: var(--text-color);
}

.footer__nav a {
  display: inline-block;
  line-height: 1.65em;
}

.footer__logos {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  color: #fff;
}

.footer__logos div {
  width: 100%;
}

.footer__logos .footer-logo {
  height: 50px;
  max-height: 50px;
  width: auto;
  display: inline-block;
  border: 0;
  outline: 0;
}

/**
 * src/css/partials/mailgo.scss
 *
 * Styles for [mailgo](https://mailgo.dev) dialog
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-22
 */
.m-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: #262e46;
}

@media (min-width: 1300px) {
  .m-modal {
    left: 11rem;
    width: calc(100vw - 11rem);
  }
}

.m-modal-content {
  margin: auto;
  width: min-content;
  background-color: #262e46;
  color: #cd5958;
  padding: 2rem;
  text-align: center;
}

.m-title {
  font-size: 1.80203rem;
  line-height: 2.24746em;
  font-weight: 400;
  margin-bottom: 1.35rem;
}

.m-modal-content {
  position: relative;
  font-size: 1.80203rem;
  line-height: 1.49831em;
}

.m-modal-content::after {
  content: 'E-Mail verfassen an ';
  width: min-content;
  white-space: nowrap;
  color: #cd5958;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: inherit;
}

#mailgo-tel .m-modal-content::after {
  content: 'Anrufen oder Direktnachricht senden';
}

.m-open, .m-copy {
  font-size: 1.80203rem;
  line-height: 1.49831em;
  display: block !important;
  margin-top: 1.35rem !important;
  margin-bottom: 1.35rem !important;
  white-space: nowrap;
  border-color: currentColor !important;
  display: inline-block;
  text-align: center !important;
}

.m-open:hover, .m-open:focus, .m-copy:hover, .m-copy:focus {
  background-color: #cd5958 !important;
  border-color: #cd5958 !important;
  color: #fff !important;
}

.m-by {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.35em;
  display: none !important;
}

#m-custom-action {
  display: none !important;
}

/**
 * Components:
 * Components are encapsulated objects that can stand-alone,
 * BEM-Syntax prefereed
 */
/**
 * src/css/components/divider.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-24
 */
.divider {
  --divider-height: 100px;
  display: grid;
  grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
  background-color: transparent;
  position: relative;
  z-index: 0;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  background-size: calc((100% - 2 * var(--margin)) / 3) 100%;
  background-position: var(--margin);
  height: var(--divider-height);
  pointer-events: none;
  position: relative;
  z-index: 2;
  /**
	 * Divider-line is a SVG (see `templates/object_templates/divider.tpl`)
	 * because only this allows half-pixels as stroke-width and thus adjusting
	 * the line's width to the stroke-width of the logo and illustrations
	 */
}

@media (min-width: 1000px) {
  .divider {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

.divider > * {
  min-width: 0;
}

.divider img {
  width: 100%;
  max-width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}

.divider > * {
  grid-column: 2 / -2;
}

@media (min-width: 1000px) {
  .divider {
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    background-size: calc(100% / 13 * 2) 100%;
    background-size: calc((100% - 11rem) / 6) 100%;
    background-position: 0 0;
  }
}

@media (min-width: 1000px) {
  .divider:first-child {
    z-index: 3;
  }
}

.divider .divider-line {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 2px;
  width: calc(100vw + var(--header-size));
  transform: translateX(calc(-1 * var(--header-size)));
  grid-column: 1 / -1;
}

.divider .divider-line line {
  stroke: #262e46;
  stroke-width: 1.15px;
}

.divider .illu {
  height: var(--divider-height);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.divider .illu svg {
  fill: #262e46;
  height: 100%;
  width: 7rem;
  max-width: 7rem;
}

.divider .illu:nth-child(1) {
  grid-column: 2 / span 1;
}

.divider .illu:nth-child(2) {
  grid-column: 3 / span 1;
}

.divider .illu:nth-child(3) {
  grid-column: 4 / span 1;
}

@media (min-width: 1000px) {
  .divider {
    --divider-height: 152px;
  }
  .divider .illu {
    display: flex;
  }
  .divider .illu svg {
    width: 9rem;
    max-width: 9rem;
    transform: translateX(-1px);
  }
  .divider > .illu:nth-child(1) {
    grid-column: 2 / span 1;
    justify-content: flex-start;
  }
  .divider > .illu:nth-child(2) {
    grid-column: 5 / span 1;
  }
  .divider > .illu:nth-child(3) {
    grid-column: 6 / span 1;
  }
}

/**
 * src/css/partials/media-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */
.media-object {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gutter);
}

.media-object [class*=body] {
  flex: 1 0 0;
}

.media-object__image {
  min-width: 30rem;
}

.media-object__image img {
  width: 100%;
}

/**
 * src/css/components/icon.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
.icon {
  height: 0.75em;
  /* ↓ Override the em value with `1cap`  where `cap` is supported */
  height: 1cap;
  width: 0.75em;
  width: 1cap;
}

.icon svg {
  fill: currentColor !important;
  stroke: none;
  display: block;
  width: 100%;
  height: 100%;
}

.with-icon {
  /* ↓ Set the `inline-flex` context,  which eliminates the word space */
  display: inline-flex;
  align-items: baseline;
}

.with-icon .icon {
  /* ↓ Use the logical margin property  and a --space variable with a fallback */
  margin-inline-end: var(--space, 0.5em);
}

/** 
 * src/css/components/headline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */
.headline {
  color: var(--accent-color);
}

/**
 * src/css/components/paragraph.scss
 *
 * Styles for object template `paragraph.tpl`
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-11
 */
.paragraph + .paragraph .body-text {
  margin-top: 1.35rem;
}

@media (min-width: 1000px) {
  .paragraph .body-text {
    grid-column: 2 / span 4;
  }
}

@media (min-width: 1300px) {
  .paragraph .body-text {
    grid-column: 2 / span 3;
  }
}

/**
 * src/css/components/eyecatcher.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */
.eyecatcher-slot {
  display: grid;
  grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
  width: 100%;
  transform: translate(-10%, 0%);
  transform-origin: 50% 50%;
  z-index: 3;
  position: fixed;
  pointer-events: none;
}

@media (min-width: 1000px) {
  .eyecatcher-slot {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

.eyecatcher-slot > * {
  min-width: 0;
}

.eyecatcher-slot img {
  width: 100%;
  max-width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}

.eyecatcher-slot > * {
  grid-column: 2 / -2;
}

.eyecatcher {
  grid-column: 4 / span 1;
  transition: transform 500ms ease-out, opacity .1s ease-in;
  transform: rotate(-20deg);
  pointer-events: all;
  justify-content: end;
  display: inherit;
}

.eyecatcher__wrapper {
  width: 6rem;
}

.eyecatcher__text {
  font-size: calc(0.25vw * 9);
  word-spacing: 10rem;
  width: min-content;
  overflow-wrap: normal !important;
}

.layout-object--word-wrap-enabled .eyecatcher__text {
  overflow-wrap: break-word;
  hyphens: auto;
}

.has-scrolled-100vh .eyecatcher {
  transform: translate(160%, -25%) rotate(-200deg);
}

.has-scrolled-100vh .eyecatacher__slot {
  pointer-events: none;
}

@media (min-width: 600px) {
  .eyecatcher__wrapper {
    width: 10rem;
  }
}

@media (min-width: 1000px) {
  .eyecatcher-slot {
    display: grid;
    grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
    min-height: 100px;
    position: fixed;
    width: calc(100% - var(--header-size));
    top: 0;
    left: 0;
    transform: translate(var(--header-size), calc(var(--divider-height) + 6 * 1.35rem));
    z-index: 2;
  }
}

@media (min-width: 1000px) and (min-width: 1000px) {
  .eyecatcher-slot {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

@media (min-width: 1000px) {
  .eyecatcher-slot > * {
    min-width: 0;
  }
  .eyecatcher-slot img {
    width: 100%;
    max-width: 100%;
    display: block;
    z-index: 1;
    position: relative;
  }
  .eyecatcher-slot > * {
    grid-column: 2 / -2;
  }
  .eyecatcher {
    grid-column: 1 / span 1;
    display: block;
  }
  .eyecatcher__wrapper {
    width: 50%;
  }
  .eyecatcher__text {
    font-size: calc(0.125vw * 7);
  }
}

.menu-is-open .eyecatcher {
  opacity: 0;
}

.eyecatcher__circle {
  height: 0;
  position: relative;
  left: 50%;
  padding-bottom: 100%;
  background-color: #262e46;
  border-radius: 50%;
  width: 100%;
}

.eyecatcher__circle::before {
  content: '';
  padding-bottom: 100%;
  height: 0;
  margin-left: -1px;
  width: 1px;
  float: left;
}

.eyecatcher__circle::after {
  content: '';
  display: table;
  clear: both;
}

.eyecatcher__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.eyecatcher__text {
  margin: auto;
  line-height: 1.1em;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  padding: 1em;
}

.eyecatcher__text a {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 1000px) {
  .eyecatcher {
    z-index: 1;
    transition-timing-function: cubic-bezier(0.74, 1.09, 0.5, 1.06);
  }
  .has-scrolled-100vh .eyecatcher {
    transform: translateX(calc((var(--header-size) + 50%) * -1)) rotate(200deg);
  }
}

@media (min-width: 1800px) {
  .eyecatcher {
    top: calc(var(--divider-height) + (3 * 1.35rem));
  }
}

.cmt-layout .eyecatcher-slot {
  position: static;
  transform: none;
  min-height: auto;
}

.cmt-layout .eyecatcher {
  width: 100%;
  transform: none;
  border: 2px solid red;
  height: 250px;
}

.cmt-layout .eyecatcher__text {
  min-width: 30px;
}

/**
 * src/css/components/introduction-hero.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */
.introduction-hero .introduction-hero__text > * + * {
  margin-top: 1.35rem;
}

.introduction-hero .introduction-hero__quote {
  grid-column: 2 / span 3;
  grid-row: 5;
  font-weight: 100;
  margin-top: 1.35rem;
}

@media (min-width: 600px) {
  .introduction-hero .introduction-hero__quote {
    grid-column: 2 / span 1;
    grid-row: auto;
    padding-right: 1rem;
  }
}

.introduction-hero .introduction-hero__main-image {
  z-index: -1;
}

.introduction-hero .introduction-hero__secondary-image {
  grid-column: 3 / -1;
  transform: translateY(-3rem);
  margin-bottom: -3rem;
  z-index: -1;
}

@media (min-width: 600px) {
  .introduction-hero .introduction-hero__headline {
    grid-column: 2 / span 2;
  }
  .introduction-hero .introduction-hero__text {
    margin-bottom: 4.05rem;
    grid-column: 2 / span 2;
  }
}

@media (min-width: 1000px) {
  .introduction-hero .introduction-hero__headline {
    grid-column: 2 / span 2;
    grid-row: 1 / 2;
    position: relative;
    max-height: 12rem;
    z-index: 1;
  }
  .introduction-hero .introduction-hero__text {
    grid-column: 5 / span 2;
    grid-row: 1 / 2;
  }
  .introduction-hero .introduction-hero__main-image {
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
  }
  .introduction-hero .introduction-hero__quote {
    grid-column: 2 / span 2;
    grid-row: 3 / 4;
  }
  .introduction-hero .introduction-hero__secondary-image {
    grid-column: 4 / span 3;
    grid-row: 3 / 4;
    transform: translateY(-6rem);
    margin-bottom: -6rem;
  }
}

/**
 * src/css/components/image-and-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */
.image-and-text .image-and-text__body {
  order: 1;
}

.image-and-text .image-and-text__image {
  order: 2;
}

@media (min-width: 1000px) {
  .image-and-text__image {
    margin-top: 0;
  }
  .image-and-text__body {
    margin-top: 8.1rem;
  }
  .image-and-text__image, .image-and-text__body {
    grid-row: 1;
  }
  .image-and-text--image-left .image-and-text__image {
    grid-column: 2 / span 2;
  }
  .image-and-text--image-left .image-and-text__body {
    grid-column: 5 / span 2;
  }
  .image-and-text--image-right .image-and-text__image {
    order: 2;
    grid-column: 5 / span 2;
  }
  .image-and-text--image-right .image-and-text__body {
    order: 1;
    grid-column: 2 / span 2;
  }
}

/**
 * src/css/components/image-and-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.quote-and-image--image-right .quote-and-image__quote {
  order: 2;
}

.quote-and-image--image-right .quote-and-image__image {
  order: 1;
}

@media (min-width: 1000px) {
  .quote-and-image__quote {
    grid-column: 2 / span 1;
    grid-row: 1;
    padding-right: 1rem;
    align-self: flex-end;
  }
  .quote-and-image__image {
    grid-column: 4 / span 3;
    grid-row: 1;
  }
  .quote-and-image--image-right .quote-and-image__quote {
    grid-column: 6 / span 1;
  }
  .quote-and-image--image-right .quote-and-image__image {
    grid-column: 2 / span 3;
  }
}

/**
 * src/css/components/full-width-image.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.full-width-image {
  /**
	 * TODO: Do we want this?
	 * check with screen designer
	 */
  max-height: 75vh;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.full-width-image img {
  grid-column: 1 / -1;
  height: auto;
  object-fit: cover;
}

/**
 * src/css/components/big-headlines-and-image.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.big-headlines-and-image {
  background: lime;
}

.big-headlines-and-image__headline, .big-headlines-and-image__headline-big {
  z-index: 2;
}

.big-headlines-and-image .headline {
  margin: 1.35rem 0;
}

@media (min-width: 1000px) {
  .big-headlines-and-image__headline {
    grid-column: 2 / span 5;
    grid-row: 1;
    margin-top: 4.05rem;
  }
  .big-headlines-and-image__headline-big {
    grid-column: 1 / span 3;
    grid-row: 2;
    margin-bottom: 1.35rem;
  }
  .big-headlines-and-image__text {
    grid-column: 2 / span 1;
    grid-row: 3;
  }
  .big-headlines-and-image__image {
    grid-column: 4 / span 4;
    grid-row: 1 / 4;
  }
}

/**
 * src/css/components/map.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.map__image {
  position: relative;
}

.map__caption {
  --pointer-height: 60px;
  --padding: 1.5%;
  position: absolute;
  width: 26%;
  width: max-content;
  max-width: 22rem;
  right: 22%;
  bottom: 49%;
  z-index: 1;
  padding: var(--padding);
  padding-bottom: calc(var(--pointer-height) + var(--padding));
  background: #262e46;
  color: #fff;
  font-size: 1rem;
  line-height: 1.35em;
  line-height: 1.1;
  clip-path: polygon(0 0, 0 calc(100% - var(--pointer-height)), 92% calc(100% - var(--pointer-height)), 100% 100%, 100% 0);
}

.map__text {
  margin-top: 4.05rem;
  margin-bottom: 1.35rem;
  grid-column: 2 / span 2;
}

@media (min-width: 600px) {
  .map__image {
    grid-column: 2 / span 2;
  }
}

@media (min-width: 1000px) {
  .map__image {
    grid-column: 2 / span 2;
  }
  .map__text {
    grid-column: 5 / span 2;
    grid-row: 1;
  }
}

/**
 * src/css/components/gastronovi-widget.scss
 *
 * Styles for the Gastronovi widget / iframe
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-11
 */
@media (min-width: 1000px) {
  .gastronovi-widget__iframe-wrapper {
    grid-column: 2 / span 4;
  }
}

@media (min-width: 1300px) {
  .gastronovi-widget__iframe-wrapper {
    grid-column: 2 / span 3;
  }
}

/**
 * src/css/components/partners.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-29
 */
.partners-list {
  display: grid;
  grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
  background-color: transparent;
  position: relative;
  z-index: 0;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  background-size: calc((100% - 2 * var(--margin)) / 3) 100%;
  background-position: var(--margin);
  gap: 4.05rem 0;
}

@media (min-width: 1000px) {
  .partners-list {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

.partners-list > * {
  min-width: 0;
}

.partners-list img {
  width: 100%;
  max-width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}

.partners-list > * {
  grid-column: 2 / -2;
}

@media (min-width: 1000px) {
  .partners-list {
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    background-size: calc(100% / 13 * 2) 100%;
    background-size: calc((100% - 11rem) / 6) 100%;
    background-position: 0 0;
  }
}

.partners-list__item {
  grid-column: 3 / span 2;
}

.partner {
  display: flex;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

.partner__body {
  padding-top: 1.35rem;
}

.partner__image {
  max-width: 100%;
  flex: 1 0 100%;
}

.partner__image img {
  aspect-ratio: 4/3;
  object-fit: cover;
}

.partner__name {
  font-weight: bold;
  color: #cd5958;
  text-transform: uppercase;
  font-size: 1.42383rem;
  line-height: 1.18519em;
  margin-bottom: 0.675rem;
  word-break: break-word;
}

.partner__action-area {
  margin-top: 1.35rem;
}

@media (min-width: 1000px) {
  .partner__image,
  .partner__body {
    flex: 1 1 100%;
  }
  .partners-list__item:nth-child(2n + 1) {
    grid-column: 2 / span 2;
  }
  .partners-list__item:nth-child(2n + 2) {
    grid-column: 5 / span 2;
  }
}

@media (min-width: 1300px) {
  .partners-list__item:nth-child(2n + 1) {
    grid-column: 3 / span 2;
  }
  .partner__image,
  .partner__body {
    flex: 0 0 50%;
  }
  .partner__body {
    padding-top: 0;
    padding-left: 2rem;
  }
  .partner__body > * {
    max-width: 14rem;
  }
}

.dropdown {
  max-height: 0;
  transition: max-height 100ms ease-in-out;
  overflow: hidden;
}

.dropdown:not([data-size]) {
  visibility: hidden;
}

@media (min-width: 1000px) {
  .dropdown__button {
    white-space: nowrap;
  }
}

.dropdown__button .icon svg {
  height: 0.75em;
  transform: translateY(-2%);
}

.dropdown__button[aria-expanded="true"] .icon {
  transform: rotate(180deg);
}

.dropdown--open {
  transition: max-height 500ms ease-in-out;
  max-height: 1000rem !important;
  padding-bottom: 0.25rem;
}

.dropdown--open:not([data-size]) {
  visibility: visible;
}

/**
 * src/css/components/news.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-19
 */
.news {
  margin-top: 0;
  padding-top: 4.05rem;
  padding-bottom: 4.05rem;
  display: grid;
  grid-template-columns: var(--margin) repeat(3, 1fr) var(--margin);
  background-color: transparent;
  position: relative;
  z-index: 0;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  background-size: calc((100% - 2 * var(--margin)) / 3) 100%;
  background-position: var(--margin);
}

@media (min-width: 1000px) {
  .news {
    grid-template-columns: repeat(6, 2fr) 11rem;
  }
}

.news > * {
  min-width: 0;
}

.news img {
  width: 100%;
  max-width: 100%;
  display: block;
  z-index: 1;
  position: relative;
}

.news > * {
  grid-column: 2 / -2;
}

@media (min-width: 1000px) {
  .news {
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
    background-size: calc(100% / 13 * 2) 100%;
    background-size: calc((100% - 11rem) / 6) 100%;
    background-position: 0 0;
  }
}

.news .news-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  grid-auto-flow: row;
}

.news-teaser {
  background-color: #f2f0f1;
  color: #262e46;
  padding: 2rem 2rem 2rem 2rem;
  outline-width: 0px;
  outline-style: solid;
  outline-color: currentColor;
  outline-offset: -1rem;
  outline: 1px solid currentColor;
  outline-offset: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.news-teaser--bg-primary {
  background-color: #262e46;
  outline: none;
}

.news-teaser--bg-accent {
  background-color: #cd5958;
  outline: none;
}

.news-teaser--bg-background {
  background-color: #f2f0f1;
}

.news-teaser--bg-white {
  background-color: #fff;
}

.news-teaser--fg-primary {
  color: #262e46;
}

.news-teaser--fg-accent {
  color: #cd5958;
}

.news-teaser--fg-background {
  color: #f2f0f1;
}

.news-teaser--fg-white {
  color: #fff;
}

.news-teaser .headline {
  color: currentColor;
  font-size: 1.26562rem;
  line-height: 1.76em;
  margin-bottom: 0.25rem;
}

.news-teaser .headline {
  font-size: 1.80203rem;
  line-height: 1.12373em;
}

.news-teaser .body-text {
  color: currentColor;
  font-size: 1.26562rem;
  line-height: 1.06667em;
  line-height: 1.35em;
}

.news-teaser__title .headline {
  font-weight: bold;
}

.news-teaser__subtitle .headline {
  font-weight: 100 !important;
}

.news-teaser .illu {
  display: none;
  width: 100%;
  margin-top: auto;
  fill: currentColor;
  padding-left: 2rem;
  padding-right: 2rem;
}

.news-teaser__subtitle .headline {
  font-weight: 400;
}

.news-teaser__text {
  margin-top: 1.35rem;
}

@media screen and (max-width: 601px) {
  .news-teaser {
    grid-column: auto / span 6;
  }
}

@media screen and (min-width: 601px) and (max-width: 1299px) {
  .news-teaser {
    grid-column: auto / span 3;
  }
}

@media screen and (min-width: 1301px) {
  .news-teaser {
    grid-column: auto / span 2;
  }
}

a.news-teaser {
  align-items: center;
  justify-content: center;
  min-height: 16.2rem;
}

a.news-teaser.news-load-next-btn {
  border: none;
  text-decoration: none;
  transition: 150ms ease-out;
}

a.news-teaser.news-load-next-btn:hover, a.news-teaser.news-load-next-btn:focus {
  background-color: #cd5958;
  color: #fff;
}

/**
 * src/css/components/opening-times.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-09-09
 */
.opening-times {
  overflow: auto !important;
}

.opening-times__headline, .opening-times__headline-big {
  z-index: 2;
}

.opening-times .headline {
  margin: 1.35rem 0;
}

.opening-times__times .headline {
  white-space: pre;
  font-variant-numeric: tabular-nums;
  text-align: right;
  transform-origin: right top;
}

@media (min-width: 1000px) {
  .opening-times__headline {
    grid-column: 2 / span 5;
    grid-row: 1;
    margin-top: 4.05rem;
  }
  .opening-times__times {
    grid-column: 1 / span 3;
    grid-row: 2;
  }
  .opening-times__image {
    grid-column: 4 / span 4;
    grid-row: 1 / 4;
  }
  .opening-times__map {
    grid-column: 2 / span 2;
    grid-row: 4 / 7;
  }
  .opening-times__map-caption {
    grid-column: 5 / span 2;
    grid-row: 6 / span 1;
  }
}

@media (min-width: 1300px) {
  .opening-times__times .headline {
    font-size: 3.25rem;
    transform: translate(-15rem);
  }
}

@media (min-width: 1800px) {
  .opening-times__times .headline {
    font-size: 5.85178rem;
    transform: translate(-15rem);
  }
}

.cmt-layout .opening-times__times .headline {
  transform: translate(0);
  width: max-content;
}

/**
 * src/css/partials/button.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version datre	
 */
.button, .m-open, .m-copy {
  color: currentColor;
  text-align: center;
  text-decoration: none;
  margin: 2px 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  padding: 0.5em 1em;
  color: #f2f0f1;
  background-color: transparent;
  outline: none;
  display: inline-flex;
  align-items: center;
  transition: 150ms ease-out;
}

.button::-moz-focus-inner, .m-open::-moz-focus-inner, .m-copy::-moz-focus-inner {
  border: none;
}

.button:active, .m-open:active, .m-copy:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.button:hover, .m-open:hover, .m-copy:hover, .button:focus, .m-open:focus, .m-copy:focus {
  background-color: #f2f0f1;
  color: transparent;
  border-color: currentColor;
}

.button > span + span, .m-open > span + span, .m-copy > span + span {
  margin-left: 0.75ch;
}

.button:hover, .m-open:hover, .m-copy:hover {
  background-color: #cd5958;
  color: #fff;
  border-color: transparent;
  outline-color: transparent;
}

pre {
  background: #fff;
  color: 000;
}
