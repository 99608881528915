/**
 * src/css/everylayout/icon.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
@mixin icon($stroke: none) {

	// Taken From EveryLayout
	height: 0.75em;
	/* ↓ Override the em value with `1cap`  where `cap` is supported */  
	height: 1cap;
	width: 0.75em;
	width: 1cap;

	svg {
		fill: currentColor !important;
		stroke: $stroke;
		display: block;
		width: 100%;
		height: 100%;
	}
}

