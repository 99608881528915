/**
 * src/css/partials/main-menu.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */
.main-menu {
	a {
		color: var(--accent-color);
		text-decoration: none;
		display: inline-block;
	}
}

.menu-trigger {

	flex: 0;
	@extend %button-reset;
	color: $primary-color;
	padding: 0;
	margin-top: 1rem;

	svg {
		display: block;
		width: 3rem;
		height: 100%;
		fill: none;
		stroke: currentColor;
		stroke-linecap: round;
		stroke-width: 8px;
	}

	.cross {
		visibility: hidden;
	}

	.hamburger {
		visibility: visible;
	}
}


.main-menu {
	position: absolute;
	top: var(--header-size);
	width: 100%;
	left: 0;
	height: calc(100vh - var(--header-size));
	background-color: var(--accent-color);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 2;

	// transform: translateX(100vw);
	transition: opacity 150ms ease-out;
	opacity: 1;
}


.menu-is-open {
	.menu-trigger .cross {
		visibility: visible;
	}
	.menu-trigger .hamburger {
		visibility: hidden;
	}
}

@media (max-width: #{$tablet-landscape-breakpoint - 1}) {
	body:not(.menu-is-open) .main-menu {
		opacity: 0;
		pointer-events: none;
		z-index: -1;
	}
}

