/**
 * src/css/partials/button.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version datre	
 */
.button {
	color: currentColor;
	@extend %button-reset;
	@include button(transparent, 0, 1px);

	transition: 150ms ease-out;

	&:hover {
		background-color: $accent-color;
		color: #fff;
		border-color: transparent;
		outline-color: transparent;
	}
}
