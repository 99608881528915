/**
 * src/css/components/eyecatcher.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */

.eyecatcher-slot {
	@include grid;
	width: 100%;
	transform: translate(-10%, -0%);
	transform-origin: 50% 50%;
	z-index: 3;
	position: fixed;
	pointer-events: none;
}

.eyecatcher {
	grid-column: 4 / span 1;
	transition: transform 500ms ease-out, opacity .1s ease-in;
	transform: rotate(-20deg);
	pointer-events: all;
	justify-content: end;
	display: inherit;
}

.eyecatcher__wrapper {
	width: 6rem;
}


.eyecatcher__text {
	font-size: calc(0.25vw * 9);

	// One word per line!
	word-spacing: 10rem; // as parent width
	width: min-content;
	// display: table-caption;
	overflow-wrap: normal !important;

}

.layout-object--word-wrap-enabled .eyecatcher__text {
	overflow-wrap: break-word;
	hyphens: auto;
}

.has-scrolled-100vh {
	.eyecatcher {
		transform: translate(160%, -25%) rotate(-200deg);
	}

	.eyecatacher__slot {
		pointer-events: none;
	}
}

@include for-size(tablet-portrait-up) {
	.eyecatcher__wrapper {
		width: 10rem;
	}
}

@include for-size(tablet-landscape-up) {
	.eyecatcher-slot {
		@include grid;
		min-height: 100px;
		position: fixed;
		width: calc(100% - var(--header-size));
		top: 0;
		left: 0;
		transform: translate(var(--header-size), calc(var(--divider-height) + 6 * #{$vertical-unit}));
		z-index: 2;
	}

	.eyecatcher {
		grid-column: 1 / span 1;
		display: block;
	}

	.eyecatcher__wrapper {
		width: 50%;
	}

	.eyecatcher__text {
		font-size: calc(0.125vw * 7);
	}
}


.menu-is-open .eyecatcher {
	opacity: 0;
}

.eyecatcher__circle {

	height: 0;
	position: relative;
	left: 50%;
	padding-bottom: 100%;
	background-color: $primary-color;
	border-radius: 50%;
	width: 100%;

	&::before {
		content: '';
		padding-bottom: 100%;
		height: 0;
		margin-left: -1px;
		width: 1px;
		float: left;
	}

	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

.eyecatcher__inner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
}

.eyecatcher__text {

	margin: auto;

	line-height: 1.1em;

	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	text-align: center;
	text-decoration: none;

	max-width: 100%;

	padding: 1em;

	a {
		text-decoration: none;
		color: #fff;
	}
}


@include for-size(tablet-landscape-up) {
	.eyecatcher {
		z-index: 1;
		transition-timing-function: cubic-bezier(.74, 1.09, .5, 1.06);
	}


	.has-scrolled-100vh {
		.eyecatcher {
			transform: translateX(calc((var(--header-size) + 50%) * -1)) rotate(200deg);
		}
	}
}

.eyecatcher {
	@include for-size(big-desktop-up) {
		top: calc(var(--divider-height) + (3 * #{$vertical-unit}));
	}
}


// Layoutmode
.cmt-layout {
	.eyecatcher-slot {
		// display: block;
		position: static;
		transform: none;
		min-height: auto;
	}

	.eyecatcher {
		width: 100%;
		transform: none;
		border: 2px solid red;
		height: 250px;
	}

	//
	// .eyecatcher__circle {
	// 	padding: 1rem !important;
	// 	height: auto !important;
	// 	min-height: 100px !important;
	// 	border-radius: 0 !important;
	// 	padding: 0;
	//
	// 	&::before, &::after {
	// 		display: none;
	// 	}
	//
	// }


	.eyecatcher__text {
		min-width: 30px;
	}
}

