/**
 * partials/_common.scss
 *
 * Common styles, like e.g. resets, basic styles...
 */

body {
	// background-image: linear-gradient(var(--accent-color), var(--primary-color));
	background-color: var(--primary-color);
	color: var(--text-color);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	&.mce-content-body {
		background-color: var(--background-color);
		padding: 1rem;
	}
}



* {
	scroll-behavior: smooth;
}

.cmt-layout {
	background-color: var(--background-color) !important;
}

.main-content {
	background-color: var(--background-color);
	flex: 1;
}

// [data-after]::after {
// 	content: attr(data-after);
// }
//
// [data-before]::before {
// 	content: attr(data-before);
// }

.button {
	@include button($button-border-width: 2px);
}

.sr-only {
	@include visually-hidden(false);
}

// .outer-bound {
// 	@include bound(var(--outer-bound-width));
// }
//
// .inner-bound {
// 	@include bound(var(--inner-bound-width));
// }

.full-width {
	@include full-width;
}

.icon {
	@include icon;
}

.cluster {
	@include cluster;
}

.stack {
	@include stack(var(--stack-spacing));
}

