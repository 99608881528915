/**
 * src/css/components/big-headlines-and-image.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.big-headlines-and-image {

	 background: lime;

	&__headline,
	&__headline-big {
		z-index: 2;
	}

	&__headline-big {
		.headline {
			// word-wrap: normal;
			// hyphens: none;
		}
	}

	.headline {
		margin: $vertical-unit 0;
	}
}

@include for-size(tablet-landscape-up) {
	.big-headlines-and-image__headline {
		grid-column: 2 / span 5;
		grid-row: 1;
		margin-top: 3 * $vertical-unit;
	}

	.big-headlines-and-image__headline-big {
		grid-column: 1 / span 3;
		grid-row: 2;
		margin-bottom: 1 * $vertical-unit;
		// background-color: purple;
	}

	.big-headlines-and-image__text {
		grid-column: 2 / span 1;
		grid-row: 3;
	}

	.big-headlines-and-image__image {
		grid-column: 4 / span 4;
		grid-row: 1 / 4;
	}
}
