/** 
 * src/css/components/headline.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */
.headline {
	color: var(--accent-color);
}
