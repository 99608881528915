/**
 * src/css/components/image-and-text.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-16
 */
.image-and-text {
	 // nothing to see here ;-)

	 .image-and-text__body {
		 order: 1;
	 }

	 .image-and-text__image {
		 order: 2;
	 }
}


@include for-size(tablet-landscape-up) {
	 .image-and-text__image {
		 margin-top: 0;
	 }

	 .image-and-text__body {
		 margin-top: $vertical-spacing-medium;
	 }

	 .image-and-text__image, .image-and-text__body {
		 grid-row: 1;
	 }

	.image-and-text--image-left {
		.image-and-text__image {
			grid-column: 2 / span 2;
		}

		.image-and-text__body {
			grid-column: 5 / span 2;
		}
	}

	.image-and-text--image-right {
		.image-and-text__image {
			order: 2;
			grid-column: 5 / span 2;
		}

		.image-and-text__body {
			order: 1;
			grid-column: 2 / span 2;
		}
	}
}
