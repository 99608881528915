/**
 * src/css/partials/footer.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.main-footer {
	margin-top: auto;
	background: var(--primary-color);
	padding-top: 3 * 2.25 * $vertical-unit;
	padding-bottom: 3 * $vertical-unit;
	min-height: 100px;
	--text-color: #fff;

	a {
		color: var(--text-color);
		display: inline-block;
	}
}

@include for-size(tablet-landscape-up) {
	.footer__logos {
		grid-column: 6 / span 1;
		grid-row: 1;
	}
	.footer__address {
		grid-column: 5 / span 1;
		grid-row: 1;
	}
	.footer__nav {
		grid-column: 2 / span 1;
		grid-row: 1;
		align-self: flex-start;
	}
}

.footer__nav {
	color: var(--text-color); // Should not be necessary imo, but it is.

	a {
		display: inline-block;
		line-height: 1.65em;
	}
}

.footer__logos {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	gap: $gutter;
	color: #fff;

	div {
		width: 100%;
	}

	.footer-logo {
		height: 50px;
		max-height: 50px;
		width: auto;
		display: inline-block;
		border: 0;
		outline: 0;
	}
}
