/**
 * src/css/partials/media-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @author Carsten Coull <c.coull@agentur-halma.de>
 * @package halma-kickstart
 * @version 2021-05-28
 */
.media-object {
	@include media-object;
}

.media-object__image {
	min-width: 30rem;
	img {
		width: 100%;
	}
}
