/**
 * src/css/layout.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */

.main-container {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	@include for-size(tablet-landscape-up) {
		flex-direction: row;
	}
}


.main-content {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	// position: relative; // Needs to be relative for calculating the eyecatcher column's width correctly
}


.spacer.grid {
	flex: 1 1 auto;
}
