/**
 * main.scss
 */

@import "settings";

/**
 * Libraries
 * Libraries do not produce any actual CSS code at all, they just provide
 * @mixins which can be used in components and partials
 */
@import "libhalma/libhalma";
@import "everylayout/everylayout";

/**
 * Partials:
 * Partials are used for general purpose stylesnot regarding a specific
 * component but more general styles, like f.e. typography, common layout,
 * global styles, header, footer etc. ...
 */
@import "partials/reset";
@import "partials/common";
@import "partials/webfonts";
@import "partials/typography";
@import "partials/grid";
@import "partials/main-header";
@import "partials/main-menu";
@import "partials/logo";
@import "partials/layout";
@import "partials/layout-object";
@import "partials/menu";
@import "partials/footer";
@import "partials/mailgo";

/**
 * Components:
 * Components are encapsulated objects that can stand-alone,
 * BEM-Syntax prefereed
 */
@import "components/divider";
@import "components/media-object.scss";
@import "components/icon";
@import "components/headline";
@import "components/paragraph";
@import "components/eyecatcher";
@import "components/introduction-hero";
@import "components/image-and-text";
@import "components/quote-and-image";
@import "components/full-width-image";
@import "components/big-headlines-and-image";
@import "components/map";
@import "components/gastronovi-widget";
@import "components/partners";
@import "components/dropdown";
@import "components/news";
@import "components/opening-times";
@import "components/button";

pre {
	background: #fff;
	color: 000;
}
