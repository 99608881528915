/**
 * src/css/partials/menu.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 */

.menu a {
	display: inline-block;
	text-decoration: none;
	color: $text-color;
	@include ms(8, 2);
}

.main-menu {
	padding-bottom: 1 * $vertical-unit;

	@include for-size(tablet-portrait-up) {
		padding-bottom: 2 * $vertical-unit;
	}

	@include for-size(tablet-landscape-up) {
		padding-bottom: 0;
	}
}

.main-nav {

	--main-menu-vertical-spacing: calc(1 * #{$vertical-unit});

	.menu {
		text-align: center;

		li + li {
			margin-top: var(--main-menu-vertical-spacing);
		}

		a {
			font-weight: bold;
			max-width: 70vw;

		}
	}

	@include for-size(tablet-portrait-up) {
		--main-menu-vertical-spacing: calc(2 * #{$vertical-unit});
	}

	@include for-size(tablet-landscape-up) {
		--main-menu-vertical-spacing: calc(1 * #{$vertical-unit});

		a {
			@include ms(1, 1);
		}
	}
}



.sec-nav {

	--social-media-size: 2.5rem;

	.menu {
		text-align: center;
		li:first-child {

			// Space for the rotated "KONTAKT" -> 6 chars
			padding-top: 6ch;
		}

		li:first-child a {
			@media screen and (min-height: 700px) {
				transform: rotate(-90deg) translateX(50%);
			}
			transform-origin: center center;
			text-transform: uppercase;
		}

		li + li {
			margin-top: 0.5 * $vertical-unit;
		}

		a {
			@include ms(6);
			
			@include for-size(tablet-landscape-up) {
				@include ms(2);
			}
		}

		svg {
			width: var(--social-media-size);
			height: var(--social-media-size);
			fill: $primary-color;
		}
	}

	@include for-size(tablet-landscape-up) {

		--social-media-size: 1.5rem;

		.menu a {
			@include ms(1);
		}
	}

	@include for-size(desktop-up) {

		--social-media-size: 1.5rem;

		.menu a {
			@include ms(1);
		}
	}
}

