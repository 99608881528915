/**
 * _ui.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 */



/**
 * Reset button
 * See: https://fvsch.com/code/styling-buttons/
 */
%button-reset {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer;
}

@mixin button ($color: $primary-color, $border-radius: $border-radius, $button-border-width: $border-width) {

	@extend %button-reset;
	$button-contrast-color: color-for-bg($color, $background-color, $text-color);

	text-align: center;
	text-decoration: none;
	margin: 2px 0;
	border-width: $button-border-width;
	border-style: solid;
	border-color: transparent;
	border-radius: $border-radius;

	padding: 0.5em 1em;
	color: $button-contrast-color; //color-for-bg($color, $background-color, $text-color);
	background-color: $color;

	outline: none;
	&::-moz-focus-inner {
		border: none;
	}

	&:active {
		transform: translateY(1px);
		filter: saturate(150%);
	}

	&:hover,
	&:focus {
		background-color: $button-contrast-color;
		color: $color; //color-for-bg($background-color, $text-color, $primary-color);
		border-color: currentColor;
	}

	// for buttons with icon
	display: inline-flex;
	align-items: center;
	> span + span {
		margin-left: 0.75ch;
	}
}


// To be done: 
// - Selects
// - Checkbox
