/**
 * src/css/components/gastronovi-widget.scss
 *
 * Styles for the Gastronovi widget / iframe
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-11
 */

.gastronovi-widget__iframe-wrapper {
	@include for-size(tablet-landscape-up) {
		grid-column: 2 / span 4;
	}
	@include for-size(desktop-up) {
		grid-column: 2 / span 3;
	}
}
