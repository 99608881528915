/**
 * src/css/partials/main-header.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-30
 */

body {
	--header-size: 13rem;

	@include for-size(tablet-landscape-down) {
		&.has-scrolled {
			--header-size: 5.9rem;
		}
	}

	@include for-size(#{$grid-breakpoint}) {
		// Must match the width of the main-header (red) column
		--header-size: #{$narrow-column-width};
	}

}


.main-header {
	flex: 0 0 var(--header-size);
	background-color: var(--accent-color);
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: var(--header-size);
	transition: 150ms ease-out;
	padding-left: var(--margin);
	padding-right: var(--margin);
	padding-top: 0;
	padding-bottom: 1rem;
	z-index: 3;

	@include for-size(#{$grid-breakpoint}) {
		padding: 0 1rem 2rem 1rem;
		height: 100vh;
		max-width: var(--header-size);
		> * {
			max-width: var(--header-size);
		}
	}
}

.main-header__top {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 100%;
	//overflow: hidden;
}

.main-nav {
	margin: auto;
}

.opening-hours {
	position: relative;
	display: flex;
	align-items: center;
	align-self: center;
	margin-top: 1rem;
	transition: opacity .1s ease-in;

	&__dropdown-trigger {
		display: flex;
		align-items: center;
		position: relative;
		background: var(--primary-color);
		color: #fff;
		padding: .5rem 1rem;
		border-radius: 100rem;
		appearance: none;
		border: none;
		cursor: pointer;

		&:after {
			--size: .6em;
			content: "";
			display: inline-block;
			margin: calc(var(--size) * -.8) 0 0 .5em;
			width: var(--size);
			height: var(--size);
			border: .2em solid;
			border-color: #fff #fff transparent transparent;
			transform: rotateZ(135deg);
		}

		&[aria-expanded="true"] {

			&:after {
				margin-top: calc(var(--size) * .5);
				transform: rotateZ(315deg);
			}
		}
	}

	&__dropdown {
		position: absolute;
		padding: 1rem !important;
		width: 18rem;
		top: 3rem;
		left: 50%;
		transform: translateX(-50%);
		background: var(--primary-color);
		color: #fff;
		border-radius: 1rem;

		th, td {
			color: #fff;
			padding: .2rem 0;
		}

		th {
			padding-right: 1em;
			text-align: left;
		}
	}
}

body.menu-is-open .opening-hours {
	opacity: 0;
}

@include for-size(tablet-landscape-up) {


	.main-header__top {
		--h: 236px;
		flex: 0 0 var(--h);
		min-height: var(--h);


		width: calc(#{$narrow-column-width} - 2rem);
		max-width: calc(#{$narrow-column-width} - 2rem);
		margin: 0 auto;

		// Is set by Javascript scroll handler!
		// But we want it only on small screens
		transform: translateY(var(--yoffset));
	}

	.menu-trigger {
		display: none;
	}

	.main-menu {
		position: static !important;
		display: flex !important;
		height: auto !important;
		flex: 1 0 auto;
	}

	.opening-hours {
		margin-left: calc((100vw - 22rem) / 6 + 1rem);
	}
}
