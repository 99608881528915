/**
 * src/css/partials/mailgo.scss
 *
 * Styles for [mailgo](https://mailgo.dev) dialog
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-22
 */

// Set to true for buttons, false for links (style)
$buttons: true;

.m-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	@include for-size(desktop-up) {
		left: $narrow-column-width;
		width: calc(100vw - #{$narrow-column-width});
	}
	height: 100vh;
	display: grid;
	align-items: center;
	justify-content: center;
	align-content: center;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.5);
	background-color: $primary-color;
	// background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.75));
}

.m-modal-content {
	margin: auto;
	width: min-content;
	background-color: $primary-color;
	color: $accent-color;
	padding: 2 * $gutter;
	text-align: center;;
	// outline: 1px solid #fff;
	// outline-offset: -$gutter;
	// box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.m-title {
	// white-space: nowrap;
	@include ms(6, 3);
	font-weight: 400;
	margin-bottom: 1 * $vertical-unit;
	// margin-top: 1 * $vertical-unit;
	// &::before {
	// 	content: '🖂  ';
	// }
}

.m-modal-content {
	position: relative;
	@include ms(6, 2);

	&::after {
		content: 'E-Mail verfassen an ';
		width: min-content;
		white-space: nowrap;
		color: $accent-color; //currentColor;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		font-size: inherit;
	}
}


#mailgo-tel .m-modal-content {
	&::after {
		content: 'Anrufen oder Direktnachricht senden';
	}
}


.m-open, .m-copy {
	@if $buttons {
		@extend .button;
		@include ms(6, 2);
		display: block !important;
		&:hover, &:focus {
			background-color: $accent-color !important;
			border-color: $accent-color !important;
			color: #fff !important;
		}
	}

	margin-top: 1 * $vertical-unit !important;
	margin-bottom: 1 * $vertical-unit !important;

	white-space: nowrap;
	border-color: currentColor !important;
	display: inline-block;
	text-align: center !important;
}


.m-by {
	display: inline-block;
	@include ms(1);
	display: none !important;
}

// Why is this here anyway? 
#m-custom-action {
	display: none !important;
}

