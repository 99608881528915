/**
 * The Cluster
 *
 * https://every-layout.dev/demos/cluster-ctas/
 *
 * We can use `gap` now on flexbox items, so it gets really easy now :-)
 */
@mixin cluster($spacing: $gutter, $use-gap: false) {
	
	> * {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		@if $use-gap {
			gap: $spacing;
		}
		@else {
			align-items: center;
			margin: calc(#{$spacing} / 2 * -1)
		}
	}

	@if $use-gap != true {
		> * > * {
			margin: calc(#{$spacing} / 2);
		}
	}
}

