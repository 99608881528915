/**
 * src/css/libhalma/media-object.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-31
 */
@mixin media-object {

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: var(--gutter);


	[class*=body] {
		flex: 1 0 0;
	}
}
