/**
 * src/css/components/full-width-image.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-17
 */
.full-width-image {
	/**
	 * TODO: Do we want this?
	 * check with screen designer
	 */
	max-height: 75vh;
	object-fit: cover;
	object-position: center;
	overflow: hidden;

	img {
		grid-column: 1 / -1;
		height: auto;
		object-fit: cover;
	}
}
