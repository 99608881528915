/**
 * src/css/libhalma/_classes.scss
 *
 * Predefined utility classes
 *
 * @author C.Coull <c.coull@agentur-halma.de>
 * @author J. Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2021-05-27
 */

// Screenreader only
@mixin visually-hidden($visible-on-focus: true) {
	// color: transparent;
	display: inline-block;
	opacity: 0;
	background: none;
	position: absolute;
	line-height: 0;
	font-size: 0;
	overflow: hidden;
	width: 2px;
	height: 2px;
	margin: -1px;

	@if $visible-on-focus {
		&:focus,
		&:active {
			opacity: 1;
			// color: inherit;
			font-size: inherit;
			line-height: inherit;
			width: auto;
			width: max-content;
			height: auto;
			height: max-content;
			margin: 0;
			z-index: 1;
		}
	}
}


@mixin bound($bound-width: $inner-bound-width, $padding: $gutter) {
	box-sizing: content-box; // Yes ;-) see EveryLayout: Center
	width: 100%;
	max-width: $bound-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: $padding;
	padding-right: $padding;
}


@mixin full-width {
	margin-left: calc(50% - 50vw);
	margin-right: calc(50% - 50vw);
}
