/**
 * src/css/components/paragraph.scss
 *
 * Styles for object template `paragraph.tpl`
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-10-11
 */
.paragraph + .paragraph {
	.body-text {
		margin-top: $vertical-unit;
	}
}

@include for-size(tablet-landscape-up) {
	.paragraph .body-text {
		grid-column: 2 / span 4;
	}
}

@include for-size(desktop-up) {
	.paragraph .body-text {
		grid-column: 2 / span 3;
	}
}
