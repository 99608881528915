/**
 * src/css/components/opening-times.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-09-09
 */
.opening-times {

	overflow: auto !important;

	&__headline,
	&__headline-big {
		z-index: 2;
	}

	.headline {
		margin: $vertical-unit 0;
	}

	&__times .headline {
		white-space: pre;
		font-variant-numeric: tabular-nums;
		text-align: right;
		transform-origin: right top;
	}

}

@include for-size(tablet-portrait-up) {
	.opening-times__headline {
		// background: yellow;
		// grid-column: 2 / span 5;
		// grid-row: 1;
		// margin-top: 3 * $vertical-unit;;
	}
}


@include for-size(tablet-landscape-up) {
	.opening-times__headline {
		grid-column: 2 / span 5;
		grid-row: 1;
		margin-top: 3 * $vertical-unit;
	}

	.opening-times__times {
		grid-column: 1 / span 3;
		grid-row: 2;
		// margin-bottom: 1 * $vertical-unit;
		// background-color: purple;
	}

	.opening-times__image {
		grid-column: 4 / span 4;
		grid-row: 1 / 4;
	}

	.opening-times__map {
		grid-column: 2 / span 2;
		grid-row: 4 / 7;
	}

	.opening-times__map-caption {
		grid-column: 5 / span 2;
		grid-row: 6 / span 1;
	}
}

@include for-size(desktop-up) {
	.opening-times__times .headline {
		font-size: 3.25rem;
		transform: translate(-15rem);
	}
}

@include for-size(big-desktop-up) {
	.opening-times__times .headline {
		font-size: 5.85178rem;
		transform: translate(-15rem);
	}
}

.cmt-layout .opening-times__times {
	.headline {
		transform: translate(0);
		width: max-content;
	}
}
