/**
 * home/jbraun/Sites/halma-kickstart/src/css/libhalma/_reset.scss
 *
 * inspired by https://hankchizljaw.com/wrote/a-modern-css-reset/
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package libhalma
 * @version 2020-11-09
 */
*, *::before, *::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html:focus-within {
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	line-height: 1.35;
	text-rendering: optimizeSpeed;
}

main {
	text-rendering: optimizeLegibility;
}

img, picture, video {
	display: block;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	object-fit: contain;
}


///////////////////
//  List styles  //
///////////////////

ol, ul {
	list-style: none;
	counter-reset: li;

	// > li {
	// 	position: relative;
	// 	padding-left: 2ch;
    //
	// 	&::before {
	// 		position: absolute;
	// 		left: 0;
	// 	}
	// }
}

ol > li::before {
	content: counter(li)'.';
	counter-increment: li;
}

// ul > li::before {
// 	content: var(--bullet-list-char, '•');
// }

input, button, textarea, select {
	font: inherit;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

@media (prefers-reduced-motion: reduce) {
	*, *::before, *::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}
