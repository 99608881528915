/**
 * src/css/components/divider.scss
 *
 * @author Johannes Braun <j.braun@agentur-halma.de>
 * @package bus
 * @version 2021-06-24
 */
.divider {
	--divider-height: 100px;

	@include grid;
	@include visible-gridlines;

	height: var(--divider-height);
	pointer-events: none;
	position: relative;
	z-index: 2;

	@include for-size(tablet-landscape-up) {
		&:first-child {
			z-index: 3;
		}
	}


	/**
	 * Divider-line is a SVG (see `templates/object_templates/divider.tpl`)
	 * because only this allows half-pixels as stroke-width and thus adjusting
	 * the line's width to the stroke-width of the logo and illustrations
	 */
	.divider-line {
		position: absolute;
		bottom: -1px;
		left: 0;
		height: 2px;
		width: calc(100vw + var(--header-size));
		transform: translateX(calc(-1 * var(--header-size)));
		grid-column: 1 / -1;
		line {
			stroke: $primary-color;
			stroke-width: 1.15px;
		}
	}


	.illu {
		height: var(--divider-height);
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		svg {
			fill: $primary-color;
			height: 100%;
			// stroke-width: 0.25px;
			// stroke: currentColor;

			// Illus should always be the same size as the logo!
			width: 7rem;
			max-width: 7rem;
		}
	}

	.illu:nth-child(1) {
		grid-column: 2 / span 1;
	}

	.illu:nth-child(2) {
		grid-column: 3 / span 1;
	}

	.illu:nth-child(3) {
		grid-column: 4 / span 1;
	}

	@include for-size(tablet-landscape-up) {

		--divider-height: 152px;

		.illu {
			display: flex;

			svg {
				// Illus should always be the same size as the logo!
				width: 9rem;
				max-width: 9rem;
				
				// fix clipping
				transform: translateX(-1px);
			}
		}


		> .illu:nth-child(1) {
			grid-column: 2 / span 1;
			// align-items: flex-start;
			justify-content: flex-start;
		}

		> .illu:nth-child(2) {
			grid-column: 5 / span 1;
		}

		> .illu:nth-child(3) {
			grid-column: 6 / span 1;
		}
	}
}
